<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'" :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl" mobile-breakpoint="960" app width="240" mini-variant-width="85" v-bind="$attrs"
    :color="barColor" :class="barColor">
    {{ changeLang() }}
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular">
          <v-row align="center" justify="center">
            <v-col style="min-height: 66px" align="center" class="ma-0 pa-0" justify="center" cols="12" md="12">
              <img class="my-2" contain width="40" src="/static/unvIcon.png" @error="errorImageHandler" />

              <!-- <img width="21px" src="../../../../assets/logo1.svg" /> -->
            </v-col>
            <v-col v-if="!expandOnHover" align="center" justify="center" class="ma-0 py-1" cols="12" md="12">
              <h4 class="mb-2 text-wrap">
                {{
    $i18n.locale == "ar" ? unvInfo.univName : unvInfo.univNameEN
  }}
              </h4>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />
    <v-list-item two-line to="/profile">
      <v-list-item-avatar>
        <img src="../../../../assets/user.svg" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle class="white--text text-wrap">{{
      getUser.fullName
    }}</v-list-item-subtitle>
        <v-list-item-subtitle class="pt-1 body-2">{{
      getUser.userName
    }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <v-list nav dense v-for="(item, i) in items" :key="i" shaped
      :class="$i18n.locale == 'ar' ? 'pr-0 py-1' : 'pl-0 py-1'" v-if="item.auth.includes(Number(getCurrentRole))">
      <v-list-item link :to="item.to" color="primary lighten-1 ">

        <v-list-item-icon>
          <v-badge color="pink lighten-1" overlap dot v-if="item.mailsCount && $store.state.mailsCount"><v-icon>{{
    item.icon }}</v-icon></v-badge>
          <v-badge color="pink lighten-1" overlap dot v-else-if="item.examsCount && $store.state.examsCount"><v-icon>{{
    item.icon }}</v-icon></v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <v-badge color="pink lighten-1" class="mx-5 mt-3" v-if="item.mailsCount" :content="$store.state.mailsCount"
          :value="$store.state.mailsCount"></v-badge>
        <v-badge color="pink lighten-1" class="mx-5 mt-3" v-if="item.examsCount" :content="$store.state.examsCount"
          :value="$store.state.examsCount"></v-badge>
      </v-list-item>
    </v-list>

    <v-footer :color="barColor" :class="barColor" absolute>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase font-weight-regular">
            <v-row align="center" justify="center" class="mb-0">
              <v-col v-if="!expandOnHover" align="center" justify="center" cols="12" sm="8" md="8" lg="8">
                <h4>
                  {{ $t("sraj") }}
                  <b class="primary--text">{{ $t("ForUniversities") }}</b>
                </h4>
              </v-col>

              <v-col :class="'text-center'">
                <img width="24px" src="../../../../assets/logo1.svg" />
              </v-col>
              <v-col class="ma-0 pa-0" v-if="!expandOnHover" align="center" cols="12">
                <v-list-item-subtitle>
                  {{ $t("version") }} {{ unvInfo.clientVersion }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";


const roles = { student: 0, teacher: 2 };

export default {
  name: "DashboardCoreDrawer",
  components: {
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          icon: "mdi-view-dashboard",
          title: this.$t("drawer.home"),
          to: "/",
          auth: [roles.student, roles.teacher],
        },
        {
          icon: "mdi-forum",
          title: this.$t("drawer.learningWebsite"),
          to: "/forums",
          auth: [roles.student, roles.teacher],
        },
        {
          icon: "mdi-bookshelf",
          title: this.$t("drawer.lectures"),
          to: "/lectures",
          auth: [roles.student, roles.teacher],
        },
        {
          icon: "mdi-school",
          title: this.$t("drawer.degrees"),
          to: "/degrees",
          auth: [roles.teacher],
        },
        {
          icon: "mdi-clipboard-alert",
          title: this.$t("drawer.alerts"),
          to: "/alerts",
          auth: [roles.student, roles.teacher],
        },
        {
          icon: "mdi-school",
          title: this.$t("drawer.degrees"),
          to: "/studentDegree",
          auth: [roles.student],
        },
        {
          icon: "mdi-account-school",
          title: this.$t("drawer.finalResults"),
          to: "/finalResults",
          auth: [roles.student],
        },
        {
          icon: "mdi-email-arrow-left",
          title: this.$t("drawer.inbox"),
          to: "/inbox",
          auth: [roles.student, roles.teacher],
          mailsCount: 0,
        },
        {
          icon: "mdi-email-arrow-right",
          title: this.$t("drawer.sent"),
          to: "/sent",
          auth: [roles.student, roles.teacher],
        },
        {
          icon: "mdi-puzzle ",
          title: this.$t("drawer.exams"),
          to: "/exams",
          auth: [roles.student, roles.teacher],
          examsCount: 0,
        },

        {
          icon: "mdi-account-question",
          title: this.$t("drawer.absences"),
          to: "/absence",
          auth: [roles.teacher],
        },
        {
          icon: "mdi-library",
          title: this.$t("drawer.library"),
          to: "/library",
          auth: [roles.student, roles.teacher],
        },

      ],
    };
  },

  created() {

  },
  computed: {
    ...mapState(["barColor"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    unvInfo() {
      return JSON.parse(localStorage.getItem("unvInfo"));
    },
    getUser() {
      return this.getUserInfo();
    },
    getCurrentRole() {
      return this.getUserInfo().userType;
    }
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    errorImageHandler() {
      this.univPictureUrl = require("../../../../assets/logo1.svg");
      this.$forceUpdate();
    },
    changeLang() {
      this.items[0].title = this.$t("drawer.home");
      this.items[1].title = this.$t("drawer.learningWebsite");
      this.items[2].title = this.$t("drawer.lectures");
      this.items[3].title = this.$t("drawer.degrees");
      this.items[4].title = this.$t("drawer.alerts");
      this.items[5].title = this.$t("drawer.degrees");
      this.items[6].title = this.$t("drawer.finalResults");
      this.items[7].title = this.$t("drawer.inbox");
      this.items[8].title = this.$t("drawer.sent");
      this.items[9].title = this.$t("drawer.exams");
      this.items[10].title = this.$t("drawer.absences");
      this.items[11].title = this.$t("drawer.library");
    },

  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
</style>
<style>
.v-navigation-drawer__content {
  padding-bottom: 150px !important;
}
</style>
